module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","name":"Marco Tisi - Full Stack Engineer","short_name":"Marco Tisi","start_url":"https://marcotisi.dev","background_color":"#1B2B34","theme_color":"#CDD3DE","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0ef4bd5f9c507390af595a8193b0cb74"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-copy-linked-files"},{"resolve":"gatsby-remark-smartypants"}],"remarkPlugins":[null],"defaultLayouts":{},"lessBabel":false,"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/Users/marcotisi/projects/marcotisi.dev"},
    },{
      plugin: require('../node_modules/@mtdev/gatsby-theme-terminal/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":"100ch","fontFamily":"source-code-pro, Monaco, Menlo, Consolas, 'Courier New', monospace","backgroundColor":"#1B2B34","textColor":"#CDD3DE","promptPrefixColor":"#99C794","promptTextColor":"#5FB3B3","promptSuffixColor":"#EC5f67","scrollbarBackgroundColor":"#4F5B66","scrollbarTrackBackgroundColor":"#4F5B66","scrollbarThumbBackgroundColor":"#A7ADBA"},
    }]
